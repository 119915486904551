/* eslint-disable react-hooks/exhaustive-deps */
import "react-confirm-alert/src/react-confirm-alert.css"

import InnerHTML from "dangerously-set-html-content"
import Papa from "papaparse"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Dropdown,
  Navbar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { DownloadCloud, Eye, Plus, Trash2 } from "react-feather"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Progress } from "reactstrap"
import Rodal from "rodal"

import {
  deleteExp,
  doJob,
  getJobStatus,
  getProject,
  killJob,
} from "../../actions/projectSlice"
import { AddRockType } from "../../components"
import ImageFolders from "../../components/ImageFolders"
import {
  EXP_DELETE_JOB,
  EXP_VIEW_JOB,
  EXPERIMENTAL_JOB,
  NETSIM_JOB,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING,
} from "../../constants/jobnames"
import { NETSIM_NETWORK_TYPES } from "../../constants/netsim"
import { tooltips } from "../../constants/tooltips"
import { isAuth } from "../../helpers/auth"
import ConsoleHelper from "../../helpers/ConsoleHelper"
// import useDebounceFn from "../../hooks/useDebounceFn"
import useJobNotification from "../../hooks/useJobNotification"
import { formatPermValue } from "../../utils/formatter"
import { ClayFolder } from "./components"

const DEFAULT_NETSIM_PROPERTIES = {
  doPD: true,
  doIMB: true,
  scanning: false,
  direction: "X",
  stopSwPD: 0,
  sow: 0.03,
  densityW: 1001,
  densityO: 401,
  oilWetFraction: 0,
  advancingWaterMin: 30,
  advancingWaterMax: 60,
  advancingOilMin: 120,
  advancingOilMax: 150,
  distribution: 1,
  krPlotsType: 0,
  pcPlotsType: 0,
  maxPressure: -1,
  rockType: false,
  rockTypeIDs: [],
}

const POLLING_TIME = 3000
const EXPERIMENTAL_POLLING_TIME = 2000
const EXP_POLLING_TIME = 500
const INPUT_TYPE = "network"
const allowedExtensions = ["csv", "plain"]

function NetsimToolView(props) {
  const [expData, setExpData] = useState([])
  const [expRecent, setExpRecent] = useState([])
  const [expLabel, setExpLabel] = useState("Exp_PC")
  const [expFile, setExpFile] = useState("")
  const [expOption, setExpOption] = useState("0")
  const [selectedImage, setSelectedImage] = useState(null)
  const [readyToLaunch, setReadyToLaunch] = useState(false)
  const [viewID, setViewID] = useState("")
  const [doPD, setDoPD] = useState(DEFAULT_NETSIM_PROPERTIES.doPD)
  const [doIMB, setDoIMB] = useState(DEFAULT_NETSIM_PROPERTIES.doIMB)
  const [direction, setDirection] = useState(
    DEFAULT_NETSIM_PROPERTIES.direction,
  )
  const [stopSwPD, setStopSwPD] = useState(DEFAULT_NETSIM_PROPERTIES.stopSwPD)
  const [sow, setSow] = useState(DEFAULT_NETSIM_PROPERTIES.sow)
  const [collapseAdvanceMenu, setCollapseAdvanceMenu] = useState(false)
  const [experimentalModel, setExperimentalModel] = useState(false) //??
  const [densityW, setDensityW] = useState(DEFAULT_NETSIM_PROPERTIES.densityW)
  const [densityO, setDensityO] = useState(DEFAULT_NETSIM_PROPERTIES.densityO)
  const [oilWetFraction, setOilWetFraction] = useState(
    DEFAULT_NETSIM_PROPERTIES.oilWetFraction,
  )
  const [advancingWaterMin, setAdvancingWaterMin] = useState(
    DEFAULT_NETSIM_PROPERTIES.advancingWaterMin,
  )
  const [advancingWaterMax, setAdvancingWaterMax] = useState(
    DEFAULT_NETSIM_PROPERTIES.advancingWaterMax,
  )
  const [advancingOilMin, setAdvancingOilMin] = useState(
    DEFAULT_NETSIM_PROPERTIES.advancingOilMin,
  )
  const [advancingOilMax, setAdvancingOilMax] = useState(
    DEFAULT_NETSIM_PROPERTIES.advancingOilMax,
  )
  const [distribution, setDistribution] = useState(
    DEFAULT_NETSIM_PROPERTIES.distribution,
  )
  const [krPlotsType, setKrPlotsType] = useState(
    DEFAULT_NETSIM_PROPERTIES.krPlotsType,
  )
  const [pcPlotsType, setPcPlotsType] = useState(
    DEFAULT_NETSIM_PROPERTIES.pcPlotsType,
  )

  const [maxPressure, setMaxPressure] = useState(
    DEFAULT_NETSIM_PROPERTIES.maxPressure,
  )
  const [scanning, setScanning] = useState(false)
  const [rockType, setRockType] = useState(DEFAULT_NETSIM_PROPERTIES.rockType)
  const [job, setJob] = useState({ status: STATUS_UNKNOWN, progress: 0 })
  const [outputName] = useState("")
  const [viewRockType, setViewRockType] = useState(null)
  const refBoxResult = useRef(null)

  const currentImage = props.project.images[viewID]
  const netsimJob = currentImage?.jobs[NETSIM_JOB]
  const netsimParams = netsimJob?.parameters

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedImages = useMemo(
    () => props.project.images,
    [JSON.stringify(props.project.images)],
  )

  const memoizedPid = useMemo(() => {
    return props.project?.pid || null
  }, [props.project?.pid])

  useEffect(() => {
    if (!netsimParams) {
      setDoPD(DEFAULT_NETSIM_PROPERTIES.doPD)
      setDoIMB(DEFAULT_NETSIM_PROPERTIES.doIMB)
      if (refImb.current)
        refImb.current.checked = DEFAULT_NETSIM_PROPERTIES.doIMB
      if (refScan.current)
        refScan.current.checked = DEFAULT_NETSIM_PROPERTIES.scanning
      setScanning(DEFAULT_NETSIM_PROPERTIES.scanning)
      if (refRockType.current)
        refRockType.current.checked = DEFAULT_NETSIM_PROPERTIES.rockType
      setRockType(DEFAULT_NETSIM_PROPERTIES.rockType)
      setDirection(DEFAULT_NETSIM_PROPERTIES.direction)
      setStopSwPD(DEFAULT_NETSIM_PROPERTIES.stopSwPD)
      setSow(DEFAULT_NETSIM_PROPERTIES.sow)
      setDensityW(DEFAULT_NETSIM_PROPERTIES.densityW)
      setDensityO(DEFAULT_NETSIM_PROPERTIES.densityO)
      setOilWetFraction(DEFAULT_NETSIM_PROPERTIES.oilWetFraction)
      setAdvancingWaterMin(DEFAULT_NETSIM_PROPERTIES.advancingWaterMin)
      setAdvancingWaterMax(DEFAULT_NETSIM_PROPERTIES.advancingWaterMax)
      setAdvancingOilMin(DEFAULT_NETSIM_PROPERTIES.advancingOilMin)
      setAdvancingOilMax(DEFAULT_NETSIM_PROPERTIES.advancingOilMax)
      setDistribution(DEFAULT_NETSIM_PROPERTIES.distribution)
      handleKrPlotsType(DEFAULT_NETSIM_PROPERTIES.krPlotsType) // render image log
      handlePcPlotsType(DEFAULT_NETSIM_PROPERTIES.pcPlotsType)
      setMaxPressure(DEFAULT_NETSIM_PROPERTIES.maxPressure)
      return
    }

    setDoPD(netsimParams.Parameters.doPD)
    setDoIMB(netsimParams.Parameters.doIMB)
    refImb.current.checked = netsimParams.Parameters.doIMB

    const isScanning = netsimParams?.ctype === "scanning"
    if (refScan.current) refScan.current.checked = isScanning
    setScanning(isScanning)

    const isGenerateRockType = netsimParams?.generateRockType
    if (refRockType.current) refRockType.current.checked = isGenerateRockType
    setRockType(isGenerateRockType)
    setDirection(netsimParams.Parameters.direction)
    setStopSwPD(netsimParams.Parameters.stopSwPD)
    setSow(netsimParams.Fluids.Sow)
    setDensityW(netsimParams.Fluids.DensityW)
    setDensityO(netsimParams.Fluids.DensityO)
    setOilWetFraction(netsimParams.Wettability.oilWetFraction)
    setAdvancingWaterMin(netsimParams.Wettability.advancingWaterMin)
    setAdvancingWaterMax(netsimParams.Wettability.advancingWaterMax)
    setAdvancingOilMin(netsimParams.Wettability.advancingOilMin)
    setAdvancingOilMax(netsimParams.Wettability.advancingOilMax)
    setDistribution(netsimParams.Wettability.distribution)
    setMaxPressure(netsimParams.Parameters.maxPressure)
    handleKrPlotsType(0) // render image log
    handlePcPlotsType(0)

    if (netsimParams.ctype === "scanning") {
      setScanning(netsimParams.ctype === "scanning")
      if (refScan.current) {
        refScan.current.checked = true
        refScan.current.disabled = false
      }
    }
  }, [netsimParams])

  const navRef = useRef(null)
  const refImb = useRef(null)
  const refPD = useRef(null)
  const refScan = useRef(null)
  const refRockType = useRef(null)
  const intervalId = useRef(null)

  const checkLaunch = useCallback(() => {
    if (
      selectedImage != null &&
      job.status !== STATUS_RUNNING &&
      job.status !== STATUS_WAITING
    ) {
      setReadyToLaunch(true)
    } else {
      setReadyToLaunch(false)
    }
  }, [job?.status, selectedImage])

  const sendUpdateRequest = useCallback(
    (iid, jid, job_type) => {
      if (viewID !== "" && job.jid !== undefined) {
        const payload = {
          pid: props.project.pid,
          iid,
          jid,
          jobname: job_type,
        }
        props.getJobStatus(payload)
      }
    },
    [viewID, props, job?.jid],
  )

  const pollApiExp = useCallback(() => {
    ConsoleHelper("Exp JobStatus Polling ", job)
    if (Object.keys(job).length) {
      if (
        (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
        job.jid === undefined
      ) {
        clearInterval(intervalId.current)
        if (job.status === STATUS_FINISHED) {
          props.getProject(props.project.pid)
        }
      } else {
        sendUpdateRequest(viewID, job.jid, EXPERIMENTAL_JOB)
      }
    }
  }, [job, props, sendUpdateRequest, viewID])

  const changeView = useCallback(
    (index) => {
      clearInterval(intervalId.current)
      setViewID(index)
      let image = props.project.images[index]
      let newJob = { status: STATUS_UNKNOWN, progress: 0 }

      if (image?.jobs?.[EXPERIMENTAL_JOB]) {
        newJob = image.jobs[EXPERIMENTAL_JOB]

        if (newJob.status === STATUS_FINISHED) {
          setJob(newJob)
          setExpData(JSON.parse(newJob.expData ?? "[]"))
          setExpOption(newJob.expOption)
          setExpLabel(newJob.expLabel)
        } else if (
          newJob.status === STATUS_RUNNING ||
          newJob.status === STATUS_WAITING
        ) {
          sendUpdateRequest(index, newJob.jid, EXPERIMENTAL_JOB)
          intervalId.current = setInterval(
            pollApiExp,
            EXPERIMENTAL_POLLING_TIME,
          )
        }
      } else {
        if (image.jobs !== undefined && image.jobs[NETSIM_JOB] !== undefined) {
          newJob = image.jobs[NETSIM_JOB]
          if (newJob.status === STATUS_FINISHED) {
            setJob(newJob)
          } else if (
            newJob.status === STATUS_RUNNING ||
            newJob.status === STATUS_WAITING
          ) {
            sendUpdateRequest(index, image.jobs[NETSIM_JOB].jid, NETSIM_JOB)
          }
        }
      }

      setSelectedImage(image)

      if (!image?.jobs) {
        let expRecent = Object.keys(image.jobs).filter((obj) => {
          if (obj.startsWith(EXPERIMENTAL_JOB + "_")) {
            return obj
          }
        })

        setExpRecent(expRecent)
      }
    },
    [pollApiExp, props?.project?.images, sendUpdateRequest],
  )

  const changeViewRockType = (rockType) => {
    setViewRockType(rockType)
  }

  useEffect(() => {
    let images = props.project.images
    let nImages = Object.keys(images).filter(
      (iid) => images[iid].type === INPUT_TYPE,
    )
    if (nImages.length > 0) changeView(nImages[0])

    return () => {
      clearInterval(intervalId.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.project.images && viewID) {
      let image = props.project.images[viewID]
      if (image && image.jobs) {
        let newJob = { status: STATUS_UNKNOWN, progress: 0 }
        if (image.jobs[EXPERIMENTAL_JOB]) {
          newJob = image.jobs[EXPERIMENTAL_JOB]
        } else if (image.jobs[NETSIM_JOB]) {
          newJob = image.jobs[NETSIM_JOB]
        }

        if (
          job.status !== newJob.status ||
          job.progress !== newJob.progress ||
          job.results !== newJob.results
        ) {
          if (
            (newJob.status !== STATUS_RUNNING &&
              newJob.status !== STATUS_WAITING) ||
            newJob.jid === undefined
          ) {
            clearInterval(intervalId.current)
          }
          if (
            job.status !== STATUS_FINISHED &&
            newJob.status === STATUS_FINISHED
          ) {
            props.getProject(props.project.pid)
          }

          setJob(newJob)
        }
      }
    }
  }, [props, viewID, job, checkLaunch])

  useJobNotification({ job })

  const removeExpData = (index) => {
    const rows = [...expData]
    rows.splice(index, 1)
    setExpData(rows)
  }

  const handleLabelChange = (e) => {
    setExpLabel(e.target.value)

    if (e.target.value === "") {
      setExpLabel(
        expOption === "1"
          ? "Exp_KRW"
          : expOption === "2"
            ? "Exp_KRO"
            : expOption === "3"
              ? "Exp_RI"
              : expOption === "4"
                ? "Exp_PC"
                : "Exp_PC",
      )
    }
  }

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target
    const list = [...expData]
    list[index][name] = value
    setExpData(list)
  }

  const pollApiExpDelete = () => {
    ConsoleHelper("Exp Delete Job Status Polling ", job)
    if (Object.keys(job).length) {
      if (
        (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
        job.jid === undefined
      ) {
        clearInterval(intervalId.current)
        if (job.status === STATUS_FINISHED) {
          props.getProject(props.project.pid)
          setTimeout(() => {
            changeView(viewID)
          }, 1000)
        }
      } else {
        sendUpdateRequest(viewID, job.jid, EXP_DELETE_JOB)
      }
    }
  }

  const pollApiExpView = () => {
    ConsoleHelper("Exp View Job Status Polling ", job)
    if (Object.keys(job).length) {
      if (
        (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
        job.jid === undefined
      ) {
        clearInterval(intervalId.current)
        if (job.status === STATUS_FINISHED) {
          props.getProject(props.project.pid)
          setTimeout(() => {
            changeView(viewID)
          }, 1000)
        }
      } else {
        sendUpdateRequest(viewID, job.jid, EXP_VIEW_JOB)
      }
    }
  }

  const pollApi = useCallback(() => {
    // ConsoleHelper("JobStatus Polling ", job)
    if (Object.keys(job).length) {
      if (
        !(
          (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
          job.jid === undefined
        )
      ) {
        sendUpdateRequest(viewID, job.jid, NETSIM_JOB)
      }
    }
  }, [job, sendUpdateRequest, viewID])

  useEffect(() => {
    if (job.status === STATUS_WAITING || job.status === STATUS_RUNNING) {
      intervalId.current = setInterval(pollApi, POLLING_TIME)
    }
    return () => clearInterval(intervalId.current)
  }, [job, pollApi])

  const deleteExpJob = (ejob, elength) => {
    setExpRecent(expRecent.filter((item) => item !== ejob))
    props.deleteExp({ vid: viewID, index: ejob, elength: elength })
  }

  const showHide = (e) => {
    e.preventDefault()

    if (navRef.current.classList.contains("collapse")) {
      navRef.current.classList.remove("collapse")
      setCollapseAdvanceMenu(true)
    } else {
      navRef.current.classList.add("collapse")
      setCollapseAdvanceMenu(false)
    }
  }

  useEffect(() => {
    checkLaunch()
  }, [selectedImage, job, checkLaunch])

  const handleKrPlotsType = (e) => {
    setKrPlotsType(e)
  }

  const handlePcPlotsType = (e) => {
    setPcPlotsType(e)
  }

  const addNewData = (e) => {
    e.preventDefault()
    setExperimentalModel(!experimentalModel)
    setExpOption(expOption === "0" ? "4" : expOption)
  }

  const submitNewData = (e) => {
    e.preventDefault()

    if (!expRecent.includes(EXPERIMENTAL_JOB + "_" + expLabel)) {
      const expArray = []

      if (job?.jid) {
        let image = props.project.images[viewID]
        expRecent.forEach((ejob) => {
          expArray.push(image.jobs[ejob].folder)
        })
      }

      let properties = {
        out_path: outputName,
        expData: expData,
        expLabel: expLabel,
        expOption: expOption,
        expRecent: expArray,
      }

      // ConsoleHelper("Launching JoB")
      let payload = {
        pid: props.project.pid,
        iid: selectedImage.iid,
        jobname: EXPERIMENTAL_JOB,
        data: { properties },
      }

      const _expRecent = expRecent.push(EXPERIMENTAL_JOB + "_" + expLabel)
      setExpRecent(_expRecent)

      props.doJob(payload)
      intervalId.current = setInterval(pollApiExp, EXPERIMENTAL_POLLING_TIME)

      setExperimentalModel(!experimentalModel)
      setExpData([])
      setExpFile("")
    } else {
      toast.error(expLabel + " already exists")
    }
  }

  const closeNewData = () => {
    setExperimentalModel(!experimentalModel)
    setExpData(job?.jid ? expData : [])
    setExpFile(job?.jid ? expFile : "")
    setExpOption(job?.jid ? expOption : "0")
  }

  const onChange = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0.0), 1.0)

    if (e.target.id === "stopSwPD") setStopSwPD(number)
    if (e.target.id === "sow") setSow(number)
    if (e.target.id === "oilWetFraction") setOilWetFraction(number)
  }

  const onChangeDensity = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0.0), 5000.0)

    if (e.target.id === "densityO") setDensityO(number)
    if (e.target.id === "densityW") setDensityW(number)
  }

  const onChangeAdvancingWater = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0.0), 90.0)

    if (e.target.id === "advancingWaterMin") setAdvancingWaterMin(number)
    if (e.target.id === "advancingWaterMax") setAdvancingWaterMax(number)
  }

  const onChangeAdvancingOil = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    if (number < 0) number = 0
    if (number > 180) number = 180

    if (e.target.id === "advancingOilMin") setAdvancingOilMin(number)
    if (e.target.id === "advancingOilMax") setAdvancingOilMax(number)
  }

  const onChangeMaxPressure = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = -1
    setMaxPressure(number)
  }

  const onChangeDoPD = () => {
    setDoPD(doPD)
  }

  const onChangeDoIMB = () => {
    setDoIMB(!doIMB)
  }

  const onChangeID = (ival) => {
    setDirection(ival)

    if (ival === "XYZ") {
      setDoIMB(false)
      setScanning(false)
      refImb.current.checked = false
      refImb.current.disabled = true
      refScan.current.checked = false
      refScan.current.disabled = true
    } else {
      setDoIMB(true)
      refImb.current.checked = true
      refImb.current.disabled = false
      refScan.current.disabled = false
    }
  }

  const onChangeScanning = () => {
    setScanning(!scanning, () => {
      if (scanning) {
        setDoIMB(true)
        setDoPD(true)
        refImb.current.checked = true
        refImb.current.disabled = true
        refPD.current.checked = true
        refPD.current.disabled = true
        refRockType.current.disabled = true
      } else {
        setDoIMB(true)
        setDoPD(true)
        refImb.current.checked = true
        refImb.current.disabled = false
        refPD.current.checked = true
        refPD.current.disabled = false
        refRockType.current.disabled = false
      }
    })
  }

  const onChangeRockType = () => {
    setRockType(!rockType)
  }

  const onChangeImage = useCallback(
    (value) => {
      changeView(value)
    },
    [changeView],
  )

  const changeExpView = (jid) => {
    let data = {
      jid: jid,
    }

    let payload = {
      pid: props.project.pid,
      iid: viewID,
      jobname: EXP_VIEW_JOB,
      data,
    }

    props.doJob(payload)
    intervalId.current = setInterval(pollApiExpView, EXP_POLLING_TIME)
  }

  const confirmDelete = (ejob) => {
    closeNewData()
    confirmAlert({
      title: "Deleting " + ejob.replace(EXPERIMENTAL_JOB + "_", "") + "?",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              jid: ejob,
              elength: expRecent.length,
            }
            // ConsoleHelper("Launching JoB")
            let payload = {
              pid: props.project.pid,
              iid: viewID,
              jobname: EXP_DELETE_JOB,
              data,
            }

            props.doJob(payload)
            intervalId.current = setInterval(pollApiExpDelete, EXP_POLLING_TIME)
            deleteExpJob(ejob, expRecent.length)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const onFileChange = (event) => {
    const inputFile = event.target.files[0]
    const fileExtension = inputFile?.type.split("/")[1]
    if (allowedExtensions.includes(fileExtension)) {
      Papa.parse(event.target.files[0], {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          const valuesArray = []
          results.data.map((d) => {
            valuesArray.push({
              sw: Object.values(d)[0],
              pc: Object.values(d)[1],
            })
            return valuesArray
          })
          setExpData(valuesArray)
        },
      })

      setExpFile(event.target.value)
    } else {
      toast.error("Please input a csv or plain file")
    }
  }

  const launchJob = () => {
    const RockTypes =
      JSON.parse(
        localStorage.getItem(`network_${viewID}_${viewRockType}_rockTypes`),
      ) || []
    const selectedRockTypes = RockTypes.filter(
      (rockType) => rockType.selected,
    ).map((rockType) => rockType.rId)

    let properties = {
      out_path: outputName,
      doPD: doPD,
      doIMB: doIMB,
      scanning: scanning,
      direction: direction,
      rockTypeIDs: selectedRockTypes,
      stopSwPD: stopSwPD,
      sow: sow,
      densityW: densityW,
      densityO: densityO,
      oilWetFraction: oilWetFraction,
      advancingWaterMin: advancingWaterMin,
      advancingWaterMax: advancingWaterMax,
      advancingOilMin: advancingOilMin,
      advancingOilMax: advancingOilMax,
      distribution: distribution,
      generateRockType: rockType,
      maxPressure: maxPressure,
    }
    // ConsoleHelper("Launching JoB")
    let payload = {
      pid: props.project.pid,
      iid: selectedImage.iid,
      jobname: NETSIM_JOB,
      data: { properties },
    }

    props.doJob(payload)
  }

  const killJob = () => {
    clearInterval(intervalId.current)
    if (job.jid !== undefined) {
      const payload = {
        pid: props.project.pid,
        iid: viewID,
        jid: job.jid,
        jobname: NETSIM_JOB,
      }

      props.killJob(payload)
    }
  }

  const LaunchButton = () => {
    if (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING)
      return (
        <button
          className="btn btn-success btn-block"
          disabled={!readyToLaunch}
          onClick={launchJob}
          type="button"
        >
          {" "}
          Launch{" "}
        </button>
      )
    else
      return (
        <button
          className="btn btn-warning btn-block"
          onClick={killJob}
          type="button"
        >
          {" "}
          Cancel{" "}
        </button>
      )
  }

  const refTransformKr = useRef(null)
  const refTransformPc = useRef(null)

  const user = isAuth().email
  const krPlotTypeStyle =
    krPlotsType >= 1
      ? {
          transform: `translateX(-${krPlotsType * (refTransformKr?.current?.offsetWidth || 640)}px)`,
        }
      : {}
  const pcPlotTypeStyle =
    pcPlotsType >= 1
      ? {
          transform: `translateX(-${pcPlotsType * (refTransformPc?.current?.offsetWidth || 640)}px)`,
        }
      : {}

  // eslint-disable-next-line react-hooks/exhaustive-deps, max-len
  const imageNetwork = useMemo(
    () =>
      memoizedImages?.[viewID]?.networkType
        ? memoizedImages?.[viewID]?.networkType
        : NETSIM_NETWORK_TYPES.Normal,
    [memoizedImages?.[viewID]?.networkType],
  )
  const isNonMultiScale = imageNetwork === NETSIM_NETWORK_TYPES.Normal

  const netsimProperties = useMemo(() => {
    if (!selectedImage) return null
    const iidParent = selectedImage?.iid
    const parentImage = props?.project?.images?.[iidParent]
    const netsimResultProperties =
      parentImage?.jobs?.netsim?.results?.Properties
    if (!netsimResultProperties) return null

    return {
      TotalPorosity: (
        (netsimResultProperties.TotalPorosity || 0.0) * 100
      ).toFixed(2),
      OpenPorosity: (
        (netsimResultProperties.OpenPorosity || 0.0) * 100
      ).toFixed(2),
      Permeability: formatPermValue(netsimResultProperties.Perm || 0.0),
      FormFactor: (netsimResultProperties.FF || 0.0).toFixed(2),
      Tortuosity: (netsimResultProperties.Tortuosity || 0.0).toFixed(2),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage, JSON.stringify(props?.project?.images ?? undefined)])

  const calcSVG = (htmlSVG) => {
    if (!htmlSVG) return ""

    const wWindow = window.innerWidth
    const wResult = refBoxResult.current.offsetWidth - 90
    let wBox

    if (wWindow > 1900) {
      wBox = wResult / 2 - 10
    } else {
      wBox = Math.min(wResult - 10, 900)
    }

    if (isNonMultiScale) {
      // aspect radio 900:600

      const hBox = (wBox * 600) / 900
      const jobKRgex = /{"width": 900.0, "height": 600.0, "axes"/g

      return htmlSVG.replace(
        jobKRgex,
        `{"width": ${wBox}, "height": ${hBox}, "axes"`,
      )
    }

    // aspect radio 640:480
    const hBox = (wBox * 480) / 640
    const jobKRgex = /{"width": 640.0, "height": 480.0, "axes"/g

    return htmlSVG.replace(
      jobKRgex,
      `{"width": ${wBox}, "height": ${hBox}, "axes"`,
    )
  }

  const jobKrlogplot = calcSVG(job?.krlogplot?.toString())
  const jobKrlinplot = calcSVG(job?.krlinplot?.toString())
  const jobPclogplot = calcSVG(job?.pclogplot?.toString())
  const jobPclinplot = calcSVG(job?.pclinplot?.toString())
  const jobRiplot = calcSVG(job?.riplot?.toString())

  return (
    <div className="toolTab flexColMedium" id="netsim">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters</h5>
          <div className="checkbox-holder-parameter">
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.netsim_p1}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="netsim-tool-p1"
            >
              <Col xs={8}> Primary Drainage (PD) </Col>
              <Col>
                <div className="custom-control custom-checkbox custom-parameter">
                  <input
                    checked={doPD}
                    className="custom-control-input"
                    id="doPD"
                    onChange={onChangeDoPD}
                    ref={refPD}
                    type="checkbox"
                  />
                  <label
                    className="label-parameter custom-control-label"
                    htmlFor="doPD"
                  ></label>
                </div>
              </Col>
            </Row>

            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.netsim_p2}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="netsim-tool-p2"
            >
              <Col xs={8}> Imbibition </Col>
              <Col>
                <div className="custom-control custom-checkbox custom-parameter margin-up-down">
                  <input
                    className="custom-control-input"
                    defaultChecked={doIMB}
                    id="doIMB"
                    onChange={onChangeDoIMB}
                    ref={refImb}
                    type="checkbox"
                  />
                  <label
                    className="label-parameter custom-control-label"
                    htmlFor="doIMB"
                  ></label>
                </div>
              </Col>
            </Row>

            <Row className="row-parameter">
              <Col xs={8}> Scanning </Col>
              <Col>
                <div className="custom-control custom-checkbox custom-parameter margin-up-down">
                  <input
                    className="custom-control-input"
                    defaultChecked={scanning}
                    id="scanning"
                    onChange={onChangeScanning}
                    ref={refScan}
                    type="checkbox"
                  />
                  <label
                    className="label-parameter custom-control-label"
                    htmlFor="scanning"
                  ></label>
                </div>
              </Col>
            </Row>

            {isNonMultiScale && (
              <Row className="row-parameter">
                <Col xs={8}>Generate RockType</Col>
                <Col>
                  <div className="custom-control custom-checkbox custom-parameter margin-up-down">
                    <input
                      className="custom-control-input"
                      defaultChecked={rockType}
                      id="rockType"
                      onChange={onChangeRockType}
                      ref={refRockType}
                      type="checkbox"
                    />
                    <label
                      className="label-parameter custom-control-label"
                      htmlFor="rockType"
                    ></label>
                  </div>
                </Col>
              </Row>
            )}

            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.netsim_p3}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="netsim-tool-p3"
            >
              <Col xs={8}> Injection Direction </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown-font custom-btn"
                    id="dropdown-basic"
                    size="sm"
                    variant="info"
                  >
                    {direction}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-font"
                    style={{ margin: 0 }}
                  >
                    <Dropdown.Item onClick={() => onChangeID("X")}>
                      {" "}
                      X{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeID("Y")}>
                      {" "}
                      Y{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeID("Z")}>
                      {" "}
                      Z{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeID("-X")}>
                      {" "}
                      -X{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeID("-Y")}>
                      {" "}
                      -Y{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeID("-Z")}>
                      {" "}
                      -Z{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeID("XYZ")}>
                      {" "}
                      MICP{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.netsim_p4}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="netsim-tool-p4"
            >
              <Col xs={8}> Min Saturation in PD </Col>
              <Col className="d-flex pr-1 align-items-center">
                <input
                  className="number-parameter"
                  id={"stopSwPD"}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.1"
                  type="number"
                  value={stopSwPD}
                />
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.netsim_p5}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="netsim-tool-p5"
            >
              <Col xs={8}> Interfacial Tension </Col>
              <Col className="d-flex pr-1 align-items-center">
                <input
                  className="number-parameter"
                  id={"sow"}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.01"
                  type="number"
                  value={sow}
                />
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.netsim_p6}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="netsim-tool-p6"
            >
              <Col xs={8}>Max Pressure</Col>
              <Col className="d-flex pr-1 align-items-center">
                <input
                  className="number-parameter"
                  id={"maxPressure"}
                  onChange={onChangeMaxPressure}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="1"
                  type="number"
                  value={maxPressure}
                />
              </Col>
            </Row>
            <ReactTooltip anchorId="netsim-tool-p1" className="tooltip-msg" />
            <ReactTooltip anchorId="netsim-tool-p2" className="tooltip-msg" />
            <ReactTooltip anchorId="netsim-tool-p3" className="tooltip-msg" />
            <ReactTooltip anchorId="netsim-tool-p4" className="tooltip-msg" />
            <ReactTooltip anchorId="netsim-tool-p5" className="tooltip-msg" />
            <ReactTooltip anchorId="netsim-tool-p6" className="tooltip-msg" />
          </div>
          <a
            aria-controls="collapseAdvanceMenu"
            aria-expanded="true"
            className="parameter_btn"
            onClick={showHide}
            role="button"
          >
            <i
              className={
                collapseAdvanceMenu
                  ? "fa fa-minus fa-fw custom-btn-icon"
                  : "fa fa-plus fa-fw custom-btn-icon"
              }
            />{" "}
            Advanced Options
          </a>
          <div className="checkbox-holder-parameter">
            <Navbar.Collapse animation="false" in={false} ref={navRef}>
              <div className="card-body-parameter card-body">
                <Row className="row-parameter">
                  <Col xs={8}> Brine Density [kg/m³]</Col>
                  <Col className="d-flex pr-1 align-items-center">
                    <input
                      className="number-parameter"
                      id={"densityW"}
                      onChange={onChangeDensity}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="0.1"
                      type="number"
                      value={densityW}
                    />
                  </Col>
                </Row>
                <Row className="row-parameter">
                  <Col xs={8}> Oil Density [kg/m³]</Col>
                  <Col className="d-flex pr-1 align-items-center">
                    <input
                      className="number-parameter"
                      id={"densityO"}
                      onChange={onChangeDensity}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="0.1"
                      type="number"
                      value={densityO}
                    />
                  </Col>
                </Row>
                <Row className="row-parameter">
                  <Col xs={8}> Oil-Wet Fraction </Col>
                  <Col className="d-flex pr-1 align-items-center">
                    <input
                      className="number-parameter"
                      id={"oilWetFraction"}
                      onChange={onChange}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="0.1"
                      type="number"
                      value={oilWetFraction}
                    />
                  </Col>
                </Row>
                <Row className="row-parameter">
                  <Col xs={8}> Min Adv. Angle Water-Wet [ ° ]</Col>
                  <Col className="d-flex pr-1 align-items-center">
                    {" "}
                    <input
                      className="number-parameter"
                      id={"advancingWaterMin"}
                      max={advancingWaterMax}
                      onChange={onChangeAdvancingWater}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="1"
                      type="number"
                      value={advancingWaterMin}
                    />
                  </Col>
                </Row>
                <Row className="row-parameter">
                  <Col xs={8}> Max Adv. Angle Water-Wet [ ° ]</Col>
                  <Col className="d-flex pr-1 align-items-center">
                    <input
                      className="number-parameter"
                      id={"advancingWaterMax"}
                      min={advancingWaterMin}
                      onChange={onChangeAdvancingWater}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="1"
                      type="number"
                      value={advancingWaterMax}
                    />
                  </Col>
                </Row>
                <Row className="row-parameter">
                  <Col xs={8}> Min Adv. Angle Oil-Wet [ ° ]</Col>
                  <Col className="d-flex pr-1 align-items-center">
                    <input
                      className="number-parameter"
                      id={"advancingOilMin"}
                      max={advancingOilMax}
                      onChange={onChangeAdvancingOil}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="1"
                      type="number"
                      value={advancingOilMin}
                    />
                  </Col>
                </Row>
                <Row className="row-parameter">
                  <Col xs={8}> Max Adv. Angle Oil-Wet [ ° ]</Col>
                  <Col className="d-flex pr-1 align-items-center">
                    <input
                      className="number-parameter"
                      id={"advancingOilMax"}
                      min={advancingOilMin}
                      onChange={onChangeAdvancingOil}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="1"
                      type="number"
                      value={advancingOilMax}
                    />
                  </Col>
                </Row>

                <Row className="row-parameter">
                  <Col xs={8}> Distribution </Col>
                  <Col className="d-flex pr-1 align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dropdown-font custom-btn"
                        id="dropdown-basic"
                        size="sm"
                        variant="info"
                      >
                        {distribution === 0
                          ? "RANDOM"
                          : distribution === 1
                            ? "SMALL"
                            : distribution === 2
                              ? "BIG"
                              : distribution === 3
                                ? "MICRO"
                                : ""}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-font">
                        <Dropdown.Item onClick={() => setDistribution(0)}>
                          {" "}
                          RANDOM{" "}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDistribution(1)}>
                          {" "}
                          SMALL{" "}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDistribution(2)}>
                          {" "}
                          BIG{" "}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDistribution(3)}>
                          {" "}
                          MICRO{" "}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>{" "}
                  </Col>
                </Row>
              </div>
            </Navbar.Collapse>
          </div>
          <h4 className="subtitle"> Input Network [{INPUT_TYPE}]</h4>
          <ImageFolders
            imageType={INPUT_TYPE}
            images={memoizedImages}
            jobType={NETSIM_JOB}
            onChangeImage={onChangeImage}
            pid={memoizedPid}
            viewID={viewID}
          />
          {!isNonMultiScale && (
            <>
              <h4 className="subtitle"> Rock Types</h4>
              <ClayFolder
                changeViewRockType={changeViewRockType}
                imageRockTypes={netsimParams?.Parameters?.rockTypeIDs ?? []}
                networkId={viewID}
                pid={props.project?.pid || null}
                projectRockTypes={props.project?.rockTypes ?? []}
                viewRockType={viewRockType}
              />
            </>
          )}
        </div>

        {!isNonMultiScale && <AddRockType project={props.project} />}

        <Progress color="success" max="100" value={job?.progress}>
          {job?.progress}%
        </Progress>
        <br />
        <LaunchButton />
      </div>
      <div className="toolResultWrapper input-grid" ref={refBoxResult}>
        <div className="tool-holder-parameter centering">
          {props.project.images[viewID]?.jobs[EXPERIMENTAL_JOB] !==
          undefined ? (
            <div className="parameters-holder-results">
              <div className="inline-holder-parameter">
                {job?.status === STATUS_RUNNING ||
                job?.status === STATUS_WAITING ? (
                  <h5 className="title">
                    Loading Data<div className="expdotloading">...</div>
                  </h5>
                ) : (
                  <h5 className="title">Experimental Results</h5>
                )}{" "}
                <div className="right-holder-parameter">
                  <div className="icon-box">
                    <a className="icon-box" href="/#">
                      <Plus
                        className="icon icon-green"
                        onClick={addNewData}
                        size={22}
                      />
                    </a>
                  </div>
                  {job?.krlogplot ? (
                    <a
                      className="icon-box"
                      href={
                        process.env.REACT_APP_API_URL +
                        "/backend/" +
                        user +
                        "/" +
                        props.project.pid +
                        "/downloadexcel/" +
                        viewID +
                        "/results.xlsx"
                      }
                    >
                      <DownloadCloud className="icon icon-green" size={22} />
                    </a>
                  ) : null}
                </div>
              </div>

              <Tabs
                className="nav nav-tabs"
                defaultActiveKey="kr"
                id="netsim-tabs"
              >
                <Tab className="netsim-tab" eventKey="kr" title="Kro/w">
                  {job?.krlinplot ? (
                    <ButtonToolbar className="parameter-toolbar">
                      <ButtonGroup className="parameter-toggle" size="sm">
                        <Button
                          active={krPlotsType === 0 ? true : false}
                          onClick={() => handleKrPlotsType(0)}
                          variant="outline-secondary"
                        >
                          log
                        </Button>
                        <Button
                          active={krPlotsType === 1 ? true : false}
                          onClick={() => handleKrPlotsType(1)}
                          variant="outline-secondary"
                        >
                          lin
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  ) : null}
                  <div className="slider">
                    <div className="slide-items" style={krPlotTypeStyle}>
                      <div className="item">
                        {job?.krlogplot ? (
                          <div>
                            <InnerHTML html={jobKrlogplot} key={job.jid + 2} />
                          </div>
                        ) : (
                          <h5 className="no-results"> No Results Yet </h5>
                        )}
                      </div>
                      <div className="item">
                        {job?.krlinplot ? (
                          <div>
                            <InnerHTML html={jobKrlinplot} key={job.jid + 3} />
                          </div>
                        ) : (
                          <h5 className="no-results"> No Results Yet </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="pc" title="Pc [Pa]">
                  {job?.pclogplot ? (
                    <ButtonToolbar className="parameter-toolbar">
                      <ButtonGroup className="parameter-toggle" size="sm">
                        <Button
                          active={pcPlotsType === 0 ? true : false}
                          onClick={() => handlePcPlotsType(0)}
                          variant="outline-secondary"
                        >
                          log
                        </Button>
                        <Button
                          active={pcPlotsType === 1 ? true : false}
                          onClick={() => handlePcPlotsType(1)}
                          variant="outline-secondary"
                        >
                          lin
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  ) : null}
                  <div className="slider">
                    <div className="slide-items" style={pcPlotTypeStyle}>
                      <div className="item">
                        {job?.pclogplot ? (
                          <div>
                            <InnerHTML html={jobPclogplot} key={job.jid + 4} />
                          </div>
                        ) : (
                          <h5 className="no-results"> No Results Yet </h5>
                        )}
                      </div>
                      <div className="item">
                        {job?.pclinplot ? (
                          <div>
                            <InnerHTML html={jobPclinplot} key={job.jid + 5} />
                          </div>
                        ) : (
                          <h5 className="no-results"> No Results Yet </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="ri" title="RI">
                  {job?.riplot ? (
                    <div>
                      <InnerHTML html={jobRiplot} key={job.jid + 6} />
                    </div>
                  ) : (
                    <h5 className="no-results"> No Results Yet </h5>
                  )}
                </Tab>
              </Tabs>
            </div>
          ) : (
            <div className="parameters-holder-results">
              <div className="inline-holder-parameter">
                <h5 className="title"> Results </h5>
                <div className="right-holder-parameter">
                  <div className="icon-box">
                    <a className="icon-box" href="/#">
                      <Plus
                        className="icon icon-green"
                        onClick={addNewData}
                        size={22}
                      />
                    </a>
                  </div>
                  {job?.krlogplot ? (
                    <a
                      className="icon-box"
                      href={
                        process.env.REACT_APP_API_URL +
                        "/backend/" +
                        user +
                        "/" +
                        props.project.pid +
                        "/downloadexcel/" +
                        viewID +
                        "/results.xlsx"
                      }
                    >
                      <DownloadCloud className="icon icon-green" size={22} />
                    </a>
                  ) : null}
                </div>
              </div>

              <Tabs
                className="nav nav-tabs"
                defaultActiveKey="kr"
                id="netsim-tabs"
              >
                <Tab className="p-3" eventKey="kr" title="Kro/w">
                  {job?.krlinplot ? (
                    <ButtonToolbar className="parameter-toolbar">
                      <ButtonGroup className="parameter-toggle" size="sm">
                        <Button
                          active={krPlotsType === 0 ? true : false}
                          onClick={() => handleKrPlotsType(0)}
                          variant="outline-secondary"
                        >
                          log2
                        </Button>
                        <Button
                          active={krPlotsType === 1 ? true : false}
                          onClick={() => handleKrPlotsType(1)}
                          variant="outline-secondary"
                        >
                          lin2
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  ) : null}
                  <div className="slider" ref={refTransformKr}>
                    <div className="slide-items" style={krPlotTypeStyle}>
                      {!job?.krlogplot && !job?.krlinplot ? (
                        <div className="item">
                          <h5 className="no-results"> No Results Yet </h5>
                        </div>
                      ) : (
                        <>
                          <div className="item">
                            {job?.krlogplot ? (
                              <div>
                                <InnerHTML
                                  html={jobKrlogplot}
                                  key={job.jid + 7}
                                />
                              </div>
                            ) : (
                              <h5 className="no-results"> No Results Yet </h5>
                            )}
                          </div>
                          <div className="item">
                            {job?.krlinplot ? (
                              <div>
                                <InnerHTML
                                  html={jobKrlinplot}
                                  key={job.jid + 8}
                                />
                              </div>
                            ) : (
                              <h5 className="no-results"> No Results Yet </h5>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab className="p-3" eventKey="pc" title="Pc [Pa]">
                  {job?.pclogplot ? (
                    <ButtonToolbar className="parameter-toolbar">
                      <ButtonGroup className="parameter-toggle" size="sm">
                        <Button
                          active={pcPlotsType === 0 ? true : false}
                          onClick={() => handlePcPlotsType(0)}
                          variant="outline-secondary"
                        >
                          log
                        </Button>
                        <Button
                          active={pcPlotsType === 1 ? true : false}
                          onClick={() => handlePcPlotsType(1)}
                          variant="outline-secondary"
                        >
                          lin
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  ) : null}
                  <div className="slider" ref={refTransformPc}>
                    <div className="slide-items" style={pcPlotTypeStyle}>
                      {!job?.pclogplot && !job?.pclinplot ? (
                        <div className="item">
                          <h5 className="no-results"> No Results Yet </h5>
                        </div>
                      ) : (
                        <>
                          <div className="item">
                            {job?.pclogplot ? (
                              <div>
                                <InnerHTML
                                  html={jobPclogplot}
                                  key={job.jid + 9}
                                />
                              </div>
                            ) : (
                              <h5 className="no-results"> No Results Yet </h5>
                            )}
                          </div>
                          <div className="item">
                            {job?.pclinplot ? (
                              <div>
                                <InnerHTML
                                  html={jobPclinplot}
                                  key={job.jid + 10}
                                />
                              </div>
                            ) : (
                              <h5 className="no-results"> No Results Yet </h5>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab
                  className="p-3 justify-content-center"
                  eventKey="ri"
                  style={{
                    display: "flex",
                  }}
                  title="RI"
                >
                  <div className="slider">
                    <div className="slide-items">
                      <div className="item">
                        {job?.riplot ? (
                          <div>
                            <InnerHTML html={jobRiplot} key={job.jid + 11} />
                          </div>
                        ) : (
                          <h5 className="no-results"> No Results Yet </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                {!isNonMultiScale ? (
                  <Tab className="p-3" eventKey="pr" title="Properties">
                    {/* <h4 className='subtitle'> Properties</h4> */}
                    {netsimProperties ? (
                      <div className="parameter">
                        {netsimProperties?.TotalPorosity ? (
                          <label className="result-label">
                            TotalPorosity [%]:
                            <label className="result">
                              {netsimProperties.TotalPorosity}
                            </label>
                          </label>
                        ) : null}
                        {netsimProperties?.OpenPorosity ? (
                          <label className="result-label">
                            OpenPorosity [%]:
                            <label className="result">
                              {netsimProperties.OpenPorosity}
                            </label>
                          </label>
                        ) : null}
                        {netsimProperties?.Permeability ? (
                          <label className="result-label">
                            Permeability [mD]: (
                            <label className="result">
                              {netsimProperties.Permeability}
                            </label>
                            )
                          </label>
                        ) : null}
                        {netsimProperties?.FormFactor ? (
                          <label className="result-label">
                            FormFactor: (
                            <label className="result">
                              {netsimProperties.FormFactor}
                            </label>
                            )
                          </label>
                        ) : null}
                        {netsimProperties?.Tortuosity ? (
                          <label className="result-label">
                            Tortuosity: (
                            <label className="result">
                              {netsimProperties.Tortuosity}
                            </label>
                            )
                          </label>
                        ) : null}
                      </div>
                    ) : (
                      <div className="slider">
                        <div className="slide-items">
                          <div className="item">
                            <h5 className="no-results"> No Results Yet </h5>
                          </div>
                        </div>
                      </div>
                    )}
                  </Tab>
                ) : null}
              </Tabs>
            </div>
          )}
        </div>
      </div>
      <Rodal
        className="newExperimentalWindow"
        closeMaskOnClick={false}
        closeOnEsc={false}
        customStyles={{
          height: "70%",
          bottom: "auto",
          top: "10%",
          width: "400px",
          overflow: "hidden auto",
        }}
        onClose={closeNewData}
        visible={experimentalModel}
      >
        <h1 className="title"> Experimental Data </h1>
        <hr className="exper-data-hr" />
        <div className="container">
          {expRecent.length !== 0 && (
            <div className="checkbox-holder-parameter">
              <Row className="row-parameter">
                <Col xs={12}> Experimental datasets </Col>
              </Row>
            </div>
          )}
          {expRecent.length !== 0 && (
            <div className="parameters-holder">
              <div className="parameter">
                {expRecent.map((ejob) => (
                  <div className="inline-parameters" key={ejob}>
                    <label
                      className="form-check-label bounded-label"
                      onClick={() => {}}
                    >
                      {ejob.replace(EXPERIMENTAL_JOB + "_", "")}
                    </label>
                    <div className="icons-holder">
                      <Eye
                        active={
                          ejob.replace(EXPERIMENTAL_JOB + "_", "") ===
                          job.expLabel
                            ? "t"
                            : "f"
                        }
                        className="icon focus-red"
                        onClick={() => changeExpView(ejob)}
                        size={16}
                      />
                      <Trash2
                        className="icon icon-red"
                        onClick={() => confirmDelete(ejob)}
                        size={16}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="checkbox-holder-parameter">
            <Row className="row-parameter">
              <Col xs={7}> Data Type </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown-font custom-btn"
                    id="dropdown-basic"
                    size="m"
                    variant="info"
                  >
                    {expOption === "1"
                      ? "KRW"
                      : expOption === "2"
                        ? "KRO"
                        : expOption === "3"
                          ? "RI"
                          : expOption === "4"
                            ? "PC"
                            : "PC"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-font">
                    <Dropdown.Item
                      onClick={() => {
                        setExpOption("1")
                        setExpLabel("Exp_KRW")
                      }}
                    >
                      KRW
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setExpOption("2")
                        setExpLabel("Exp_KRO")
                      }}
                    >
                      KRO
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setExpOption("3")
                        setExpLabel("Exp_RI")
                      }}
                    >
                      RI
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setExpOption("4")
                        setExpLabel("Exp_PC")
                      }}
                    >
                      PC
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </div>
          <div className="checkbox-holder-parameter">
            <Row className="row-parameter">
              <Col xs={7}> Label </Col>
              <Col>
                <input
                  className="text-parameter"
                  onChange={handleLabelChange}
                  placeholder={
                    expOption === "1"
                      ? "Exp_KRW"
                      : expOption === "2"
                        ? "Exp_KRO"
                        : expOption === "3"
                          ? "Exp_RI"
                          : expOption === "4"
                            ? "Exp_PC"
                            : "Exp_PC"
                  }
                  type="text"
                  value={expLabel}
                />
              </Col>
            </Row>
          </div>
          <div className="checkbox-holder-parameter custom-file">
            <input
              className="custom-file-input"
              id="inputGroupFile01"
              onChange={onFileChange}
              size="sm"
              type="file"
              value={expFile}
            />
            <label className="custom-file-label" htmlFor="inputGroupFile01">
              Select file
            </label>
          </div>
          <div className="expRow">
            <div className="expCol">
              {expData.map((data, index) => {
                const { sw, pc } = data
                return (
                  <div className="expRow" key={index}>
                    <div className="expCol">
                      <input
                        className="form-control"
                        name="sw"
                        onChange={(evnt) => handleChange(index, evnt)}
                        placeholder="#Sw"
                        type="text"
                        value={sw}
                      />
                    </div>
                    <div className="expCol">
                      <input
                        className="form-control"
                        name="pc"
                        onChange={(evnt) => handleChange(index, evnt)}
                        placeholder="#Pc"
                        type="text"
                        value={pc}
                      />
                    </div>
                    <div className="expCol">
                      <button
                        className="btn btn-outline-danger"
                        onClick={(evnt) => removeExpData(index, evnt)}
                      >
                        <Trash2 className="icon" size={22} />
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="button-holder">
          {expData.length > 0 ? (
            <Button
              className="btn btn-success"
              disabled={
                job.status === STATUS_RUNNING || job.status === STATUS_WAITING
              }
              onClick={submitNewData}
            >
              {" "}
              Add Data{" "}
            </Button>
          ) : (
            ""
          )}
        </div>
      </Rodal>
    </div>
  )
}

NetsimToolView.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired,
  }).isRequired,
  deleteExp: PropTypes.func.isRequired,
  doJob: PropTypes.func.isRequired,
  killJob: PropTypes.func.isRequired,
  getJobStatus: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
}

export default connect(null, {
  deleteExp,
  doJob,
  killJob,
  getJobStatus,
  getProject,
})(NetsimToolView)
